// Домашняя страница Агроаналитики

// 'Главная', 'Сводка', 'Расчет хим.состава', 'Подсчёт рулонов', 'Карта урожайности', 'Карта сорняков', 'Настройки'

import React from 'react';
import { AppBar, Toolbar, Typography, CssBaseline, Drawer, List, ListItem, ListItemText, Box, Container } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

// Импортируем компоненты основного окна
import Agrochemistry from './agrochemistry/Agrochemistry';
import Rolls from './count_rolls/Rolls';
import Settings from './Settings'
import Menu from './Menu';



function App() {
    return (
        <Router>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                {/* Шапка */}
                <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Toolbar>
                        <Menu />
                        <Typography variant="h6" noWrap>
                            Агроаналитический сервис
                        </Typography>
                    </Toolbar>
                </AppBar>

                {/* Левое меню */}
                {/* <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    }}
                >
                    <Toolbar />
                    <Box sx={{ overflow: 'auto' }}>
                        <List>
                            <ListItem button component={Link} to="/">
                                <ListItemText primary="Главная" />
                            </ListItem>
                            <ListItem button component={Link} to="/agrochemistry">
                                <ListItemText primary="Агрохимия" />
                            </ListItem>
                            <ListItem button component={Link} to="/rolls">
                                <ListItemText primary="Подсчёт рулонов" />
                            </ListItem>
                            <ListItem button component={Link} to="/settings">
                                <ListItemText primary="Настройки" />
                            </ListItem>
                        </List>
                    </Box>
                </Drawer> */}

                {/* Основное содержимое страницы */}
                <Box
                    component="main"
                    sx={{ flexGrow: 1, bgcolor: '#666', p: 1, width: '100vh' }}
                >
                    <Toolbar />
                    {/* Центрует положение */}
                    {/* <Container maxWidth="lg"> */}
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/agrochemistry" element={<Agrochemistry />} />
                        <Route path="/rolls" element={<Rolls />} />
                        <Route path="/settings" element={<Settings />} />
                    </Routes>
                    {/* </Container> */}
                </Box>
            </Box>
        </Router>
    );
}

// Компоненты страниц

function Home() {
    return (
        <>
            <Typography variant="h5" gutterBottom>
                Сводка данных
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                <Skeleton variant="rectangular" width={300} height={200} />
                <Skeleton variant="rectangular" width={300} height={200} />
                <Skeleton variant="rectangular" width={300} height={200} />
            </Box>
        </>
    );
}

function Reports() {
    return (
        <>
            <Typography variant="h5" gutterBottom>
                Отчеты
            </Typography>
            <Skeleton variant="rectangular" width="100%" height={400} />
        </>
    );
}
export default App;