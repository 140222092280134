import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, Box, Typography, IconButton, Skeleton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const Agrochemistry = (url) => {
  const apiHost = process.env.REACT_APP_API_HOST;
  const [data, setData] = useState(null);

  useEffect(() => {
    // Функция для загрузки данных с сервера
    const fetchDataFromServer = async () => {
      try {
        const response = await fetch(`${apiHost}${'/chemistry/2024'}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        // Сохраняем данные в IndexedDB
        // saveDataToIndexedDB(jsonData);
        // console.log(jsonData);
        setData(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
        // setError(error);
      } finally {
        // setLoading(false);
      }
    };
    fetchDataFromServer();
  }, []);

  // if (!data) {
  //   // console.log('Loading');
  //   return (
  //     <div>Загрузка данных</div>
  //   )
  // }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="right">ID</TableCell>
            <TableCell align="right">Р. поле</TableCell>
            <TableCell align="right">Год</TableCell>
            <TableCell align="right">Площадь посева</TableCell>
            <TableCell align="right">Культура след. года</TableCell>
            <TableCell align="right">Культура прош. года</TableCell>

            <TableCell align="right">Кислотность</TableCell>
            <TableCell align="right">Гумус</TableCell>
            <TableCell align="right">Калий</TableCell>
            <TableCell align="right">Фосфор</TableCell>

            <TableCell align="right">Почва</TableCell>
          </TableRow>
        </TableHead>
        {/* Группировка по  полям. Сворачиваемые */}
        <TableBody>
          {data ? (
            data.map((row) => (
              // Извлекаем посевы построчно
              <Row props={row} key={row.id}></Row>
            ))
          ) : (
            <TableRow>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </TableRow>
          )

          }


        </TableBody>
      </Table>
    </TableContainer >
  );
}

function Row({ props }) {
  "Разбивает родительское поле на посевы"

  // const [open, setOpen] = useState(props.sts);

  if (!props) {
    // console.log('Loading');
    return (
      <div>Загрузка данных</div>
    )
  }

  return (
    <React.Fragment>
      {/* По умолчанию используются данные с родительской таблицы (тип почвы и т.п.) */}
      {/* <TableRow>
        {sowing.map((row) => (
          <>
        <TableCell align="right">{props?.id}</TableCell>
        <TableCell align="right">{props?.parent}</TableCell>
        <TableCell align="right">{props?.year}</TableCell>
        <TableCell align="right">{props?.area}</TableCell>
        <TableCell align="right">{props?.crop}</TableCell>
        <TableCell align="right">{props?.old_crop}</TableCell>

        <TableCell align="right">{props?.acidity}</TableCell>
        <TableCell align="right">{props?.humus}</TableCell>
        <TableCell align="right">{props?.kalium}</TableCell>
        <TableCell align="right">{props?.phosphorus}</TableCell>

        <TableCell align="right">{props?.soil}</TableCell>
        </>
        ))}
      </TableRow> */}

    </React.Fragment >
  )
}

export default Agrochemistry;