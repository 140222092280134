// Подсчёт рулонов
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { List, ListItem, ListItemText, Skeleton, ListItemAvatar, Avatar } from '@mui/material';

import { Breadcrumbs, Link } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';

import ImageIcon from '@mui/icons-material/Image';
function Rolls() {
    return (
        <>
            <Typography variant="h5" gutterBottom>
                Подсчёт рулонов
            </Typography>
            <Photo />   
            {/* <FolderPath path="root/folder/subfolder"/> */}

        </>
    );
}

export default Rolls;

function Photo() {
    const apiHost = process.env.REACT_APP_API_HOST;
    const [data, setData] = useState(null);

    useEffect(() => {
        // Функция для загрузки данных с сервера
        const fetchDataFromServer = async () => {
            try {
                const response = await fetch(`${apiHost}/photos`);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const jsonData = await response.json();
                setData(jsonData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataFromServer();
    }, [apiHost]);

    return (
        <>
            {/* <Typography sx={{ color: "#e60c0c" }}>
                Всего снимков: {data?.length}
            </Typography> */}
            <List dense={true} sx={{ width: '100%', maxWidth: 240, bgcolor: 'background.paper' }}>
                {data ? (
                    data.map((row) => (
                        <ListItem>
                            <ListItemText primary={row?.name} />
                            <ImageIcon />
                        </ListItem>

                    )))
                    :
                    (
                        <>
                            <ListItem>
                                <Skeleton width={240} />
                            </ListItem>
                            <ListItem>
                                <Skeleton width={240} />
                            </ListItem>
                            <ListItem>
                                <Skeleton width={240} />
                            </ListItem>
                        </>
                    )}

            </List>
        </>
    )
}






function FolderPath({ path }) {
    const pathArray = path.split('/').filter(Boolean);

    return (
        <Breadcrumbs aria-label="breadcrumb">
            {pathArray.map((folder, index) => {
                const isLast = index === pathArray.length - 1;
                const to = `/${pathArray.slice(0, index + 1).join('/')}`;

                return isLast ? (
                    <Typography key={to} color="text.primary">
                        <FolderIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        {folder}
                    </Typography>
                ) : (
                    <Link key={to} href={to} color="inherit">
                        <FolderIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        {folder}
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
}
